import { Route, Routes } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { msalInstance } from "./index";
import Home from "./pages/Home";
import MSLogin from './pages/MSLogin';
import Tablets from './pages/Tablets';
import Mats from './pages/Mats';
import Neurostimulators from './pages/Neurostimulators';
import Sessions from './pages/Sessions';
import Explore from './pages/Explore';
import GenericSessions from './pages/GenericSessions';
import Unauthed from './components/Unauthed';
import TabletDetail from './pages/TabletDetail';
import CliffordOverview from './pages/CliffordOverview';
import SessionDetail from './pages/SessionDetail';
import PageNotFound from './pages/PageNotFound';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import { isDevMode } from './index';
import OurAuthTokenWrapper from './OurAuthTokenWrapper';
import Box from '@mui/joy/Box';
import Sidebar from './components/Sidebar';
import OurBreadcrumbs from './components/OurBreadcrumbs';
import * as Constants from './Constants';
import ExploreDetail from './pages/ExploreDetail';
import SingleSeries from './pages/SingleSeries';
import Report from './pages/Report';
import BugReports from './pages/BugReports';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

const MainContent = () => {
    return (
        <>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='mslogin' element={<MSLogin />} />
                <Route path='tablets' element={<Tablets />} />
                <Route path='mats' element={<Mats />} />
                <Route path='clifford_overview' element={<CliffordOverview />} />
                <Route path='neurostimulators' element={<Neurostimulators />} />
                <Route path='sessions' element={<Sessions />} />
                <Route path='report' element={<Report />} />
                <Route path='bug_reports' element={<BugReports />} />
                <Route path='explore' element={<Explore />} />
                <Route path='explore_detail' element={<ExploreDetail />} />
                <Route path='generic_sessions' element={<GenericSessions />} />
                <Route path='session_detail/:id' element={<SessionDetail />} />
                <Route path='single_series' element={<SingleSeries />} />
                <Route path='tablet_detail/:serial_number' element={<TabletDetail />} />
                <Route path='*' element={<PageNotFound />} />
            </Routes>
        </>
    );
};

export default function App() {
    const wsUrl = isDevMode ? Constants.WS_URL_DEV : Constants.WS_URL_PROD;
    useWebSocket(wsUrl, {
      share: true,
      onOpen: () => {
        // This really worked?
      },
      onClose: () => {
      }
      // TODO: figure out how to make this connection heal if it breaks
    });

    return (
        <QueryClientProvider client={queryClient}>
            <CssVarsProvider>
                <CssBaseline />
                <MsalProvider instance={msalInstance}>
                    <AuthenticatedTemplate>
                    <OurAuthTokenWrapper>
                    <Box sx={{ display: 'flex', minHeight: '100dvh', paddingTop: '0' }}>
                        <Sidebar />
                        <Box
                        component="main"
                        className="MainContent"
                        sx={{
                            px: { xs: 2, md: 6 },
                            pt: {
                            xs: 'calc(12px + var(--Header-height))',
                            sm: 'calc(12px + var(--Header-height))',
                            md: 1,
                            },
                            pb: { xs: 2, sm: 2, md: 3 },
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            minWidth: 0,
                            height: '100dvh',
                            gap: 1,
                            overflow: 'auto',
                        }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <OurBreadcrumbs />
                            </Box>

                            <MainContent />
                        </Box>
                    </Box>

                    </OurAuthTokenWrapper>
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                        <Unauthed />
                    </UnauthenticatedTemplate>
                </MsalProvider>
            </CssVarsProvider>
        </QueryClientProvider>
    );
}