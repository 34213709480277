import Typography from '@mui/joy/Typography';
import { useEffect } from 'react';

export default function Home() {
    useEffect(() => {
        document.title = `Arcadia - Home`;
    }, []);

    return (
        <>
            <Typography level="h2" component="h1">Arcadia Home</Typography>
            TBD: landing page, dashboard, recent BIST failures, some system summary<br />
        </>
    );
}