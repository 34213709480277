import { useState, useEffect } from 'react';
import { NetworkHelper } from '../util/NetworkHelper';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import { useColorScheme } from '@mui/joy/styles';
import { getEvenRowTableBackground, getOddRowTableBackground } from '../utils';
import Link from '@mui/joy/Link';
import { Link as RouterLink } from "react-router-dom";

// TODO: figure out how to re-use these sheet/table combos we do with
// so much inline style duplication

export default function Explore() {
    const net = NetworkHelper.getInstance();
    const [metadataList, setMetadataList] = useState<any[]>([]);
    const { mode } = useColorScheme();
    const rowEvenBackground = getEvenRowTableBackground(mode!);
    const rowOddBackground = getOddRowTableBackground(mode!);

    useEffect(() => {
      document.title = "Arcadia - Explore";
  }, []);

    useEffect(() => {
        let cancelMe = false;

        const doAsyncWork = async () => {
            const result = await net.doGetUniqueMetadata();

            if (!cancelMe) {
                setMetadataList(result);
            }
        }

        doAsyncWork().catch((error) => console.error(error));

        return () => {
            net.abort();
            cancelMe = true;
        }
    }, [net]);

    if (metadataList) {
        return (
        <>
        <Typography level="h2" component="h1">Explore</Typography>
        <span>
            This is a list of all unique metadata <u>keys</u> in the system. To view the distinct values
            for a <u>key</u>, click the link in the "Unique Values" column.
        </span>
        <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: 'none', sm: 'initial' },
          width: '100%',
          borderRadius: '1px',
          borderWidth: '1px',
          flexShrink: 1,
          overflow: 'auto',
          minHeight: 0,
          marginTop: '16px'
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
            '--TableCell-paddingY': '4px',
            '--TableCell-paddingX': '8px',
          }}
        >
            <thead>
                <tr>
                    <th style={{ width: 80, padding: '12px 6px' }}>Key</th>
                    <th style={{ width: 40, padding: '12px 6px' }}>Unique Values</th>
                </tr>
            </thead>
            <tbody>
            {metadataList.map((row:any, index:number) => (
              <tr style ={ index % 2? { background : rowEvenBackground }:{ background : rowOddBackground }} key={row.key}>
                <td>
                  <Typography level="body-sm">{row.key}</Typography>
                </td>
                <td>
                  <Link to={`/explore_detail?key=${row.key}`} component={RouterLink}>{row.value_count}</Link>
                </td>
              </tr>
            ))}
            </tbody>
        </Table>
        </Sheet>
        </>
        );
    }
    else {
        return <p>Fetching metadata list...</p>
    }

}