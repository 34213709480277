import { useParams } from 'react-router-dom';
import { NetworkHelper } from '../util/NetworkHelper';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { FormControl, Select, Typography, Option, Button } from '@mui/joy';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import QRCode from "react-qr-code";
import CheckIcon from '@mui/icons-material/Check';
import { useState } from 'react';
import DeviceLogTable from '../components/DeviceLogTable';

// Take all the stuff we want in the QR code and turn it into a JSON string
// for encoding.
function tabletRowToJSON(row: any):string {
    return JSON.stringify({
        upload_key: row.upload_key,
        public_key: row.public_key,
        serial: row.serial_number,
        clinic_name: row.clinic_name,
        clinic_id: row.clinic_id
    });
}

interface TabletForm {
    clinic_id: number | null
}

export default function TabletDetail() {
    const [isLogVisible, setLogVisible] = useState(true);
    const queryClient = useQueryClient();
    const { serial_number } = useParams();

    const {
        control,
        reset,
        handleSubmit,
        formState: { isDirty }
      } = useForm<TabletForm>(({
        defaultValues: {
            clinic_id: 0
        },
      }));

    const tabletDetailQuery = useQuery({
        queryKey: ['getTablet', serial_number],
        queryFn: async () => {
            const data = await NetworkHelper.getInstance().doGetTablet(serial_number || '')
            console.log(`query done: data = ${JSON.stringify(data)}`);

            // Coerce null clinic_id to 0 so it hits on the first item in the dropdown.
            if (data.clinic_id === null) {
                data.clinic_id = 0;
            }
            reset(data);
            return data
        }
    });

    const clinicsQuery = useQuery({
        queryKey: ['getClinics'],
        queryFn: () => NetworkHelper.getInstance().doGetClinics()
    });

    const updateClinicMutation = useMutation({
        mutationFn: (options: any) => NetworkHelper.getInstance().doSetTabletClinic(options.serial_number, options.clinic_id),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['getTablet', serial_number] });
        }
    });

    const onSubmit: SubmitHandler<TabletForm> = (formdata) => {
        console.log(`onSubmit, dirty test: ${control.getFieldState("clinic_id").isDirty}`);
        updateClinicMutation.mutate({ serial_number: serial_number, clinic_id: formdata.clinic_id });
    }

    const logButtonClicked = () => {
        if (isLogVisible) {
            queryClient.invalidateQueries({ queryKey: ['getDeviceLog', tabletDetailQuery.data.id] });
        }
        else {
            setLogVisible(true);
        }
    }

    if (!tabletDetailQuery.isSuccess || !clinicsQuery.isSuccess) {
        return <p>Loading...</p>;
    }

    return (
        <>
            <Typography level="h2" component="h1">Tablet Detail</Typography>
            <br />
            <form onSubmit={handleSubmit(onSubmit)}>
            <table width="400px">
                <tbody>
                    <tr>
                        <td style={{ float: 'right' }}>
                            <b>Serial:</b>
                        </td>
                        <td>
                            {serial_number}
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'right', verticalAlign: "middle", "height": "50px" }}>
                            <b>Clinic:</b>
                        </td>

                        <td>
                        <FormControl sx={{ width: "250px" }} size="sm">
                            <Controller
                                name="clinic_id"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                    {...field}
                                    required={true}
                                    onChange={(_event, newValue) => { 
                                        field.onChange(newValue); 
                                    }}
                                    placeholder="Choose one…">
                                        <Option key={0} value={0}>None</Option>
                                        {clinicsQuery.data.map((row:any, index:number) => (
                                            <Option key={row.id} value={row.id}>{row.name}</Option>
                                        ))}

                                    </Select>
                                    )}
                                />

                        </FormControl>
                        </td>

                        <td>
                        <Button
                            type="submit"
                            color="primary"
                            startDecorator={<CheckIcon />}
                            size="sm"
                            sx={{ height: '32px', visibility: control.getFieldState("clinic_id").isDirty ? "visible" : "hidden" }}
                            >
                            Update
                        </Button>
                        </td>
                    </tr>
                    </tbody>
            </table>
            </form>
            <br />
            <div>
                <Button 
                    type="submit" 
                    size="sm" 
                    color={ isLogVisible ? "primary" : "neutral"} 
                    onClick={ logButtonClicked }
                    sx={{ height: '32px',  width: '100px', display: "inline-block", flex: 0 }}>
                        Log
                </Button> 
                &nbsp; &nbsp;
                <Button 
                    type="submit" 
                    size="sm" 
                    color={ isLogVisible ? "neutral" : "primary"} 
                    onClick={ () => setLogVisible(false) } 
                    sx={{ height: '32px', width: '100px', display: "inline-block", flex: 0 }}>
                        Provision
                </Button>
            </div>                
            <br />

            <div style={{ width: "auto", height: "50%", margin: "0 auto", backgroundColor: "white", padding: "16px", display: isLogVisible ? "none" : "flex" }}>
                <QRCode
                    size={256}
                    style={{ height: "100%", width: "100%" }}
                    value={tabletRowToJSON(tabletDetailQuery.data)}
                    level="H"
                    viewBox={`0 0 256 256`}
                />
            </div>

            <DeviceLogTable tablet_id={tabletDetailQuery.data.id} display={isLogVisible ? "flex" : "none"} />
        </>
    );
}