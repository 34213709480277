import { getEvenRowTableBackground, getOddRowTableBackground } from '../utils';
import { useColorScheme } from '@mui/joy/styles';
import Typography from '@mui/joy/Typography';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import { localizeEpochJustTime } from '../utils';

interface LogEntry {
    index: number;
    src: string;
    level: string;
    timestamp: number;
    contents: string;
};

interface PassiveLogProps {
    log_entries: LogEntry[];
}

export default function PassiveLogTable(props: PassiveLogProps) {
    const { mode } = useColorScheme();
    const rowEvenBackground = getEvenRowTableBackground(mode!);
    const rowOddBackground = getOddRowTableBackground(mode!);

    const colorForLevel = (level:string) => {
        if (level === "I" || level === "D") {
            return "";
        }
        else if (level === "W") {
            return "orange";
        }

        return "red";
    }
    return (
        <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
            width: '100%',
            borderRadius: '1px',
            borderWidth: '1px',
            flexShrink: 0,
            minHeight: 0,
        }}>
            <Table
                aria-labelledby="tableTitle"
                sx={{
                    '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                    '--Table-headerUnderlineThickness': '1px',
                    '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                    '--TableCell-paddingY': '4px',
                    '--TableCell-paddingX': '8px',
                }}
            >
                <thead>
                    <tr>
                        <th style={{ width: "60px", padding: '12px 6px' }}>Src</th>
                        <th style={{ width: "60px", padding: '12px 6px' }}>Level</th>
                        <th style={{ width: "160px", padding: '12px 6px' }}>Timestamp</th>
                        <th style={{ width: "auto", padding: '12px 6px' }}>Contents</th>
                    </tr>
                </thead>
                <tbody>
                    {props.log_entries.map((log_entry:any, index:number) => (
                    <tr style={ index % 2? { background : rowEvenBackground }:{ background : rowOddBackground }} key={index}>
                        <td >
                            <Typography level="body-sm" style={{color: colorForLevel(log_entry.level)}}>{ log_entry.src }</Typography>
                        </td>
                        <td>
                            <Typography level="body-sm" style={{color: colorForLevel(log_entry.level)}}>{ log_entry.level }</Typography>
                        </td>
                        <td>
                            <Typography level="body-sm" style={{color: colorForLevel(log_entry.level)}}>{ localizeEpochJustTime(log_entry.timestamp) }</Typography>
                        </td>
                        <td>
                            <Typography level="body-sm" style={{color: colorForLevel(log_entry.level)}}>{ log_entry.contents }</Typography>
                        </td>
                    </tr>
                    ))}
            </tbody>
            </Table>
        </Sheet>
    );
}