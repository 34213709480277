import { Button } from '@mui/joy';
import { loginRequest } from '../msalConfig';
import { useMsal } from '@azure/msal-react';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';


export default function Unauthed() {
    const { instance } = useMsal();
    const handleLoginMSAL = () => {
        instance.loginRedirect(loginRequest).catch((error) => console.log(error));
    };

    const handleLoginEmail = () => {
        alert("Email login not implemented yet, sorry.")
    };

    return (
        <>
            <Sheet
                sx={{
                    width: 400,
                    minHeight: 300,
                    mx: 'auto', // margin left & right
                    my: 4, // margin top & bottom
                    py: 3, // padding top & bottom
                    px: 2, // padding left & right
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    borderRadius: 'sm',
                    boxShadow: 'md',
                }}>
                <Typography level="h4" component="h1">Arcadia</Typography>
                <p>
                You are not signed in, please select an authentication 
                option from below to continue.<br /><br />

                <Button onClick={handleLoginMSAL}>Sign in with Microsoft</Button> &nbsp; &nbsp;
                <Button onClick={handleLoginEmail}>Sign in with email</Button> &nbsp; &nbsp;
                </p>
            </Sheet>
        </>
    );
}

