import Breadcrumbs from '@mui/joy/Breadcrumbs';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import Link from '@mui/joy/Link';
import { Link as RouterLink, useLocation } from "react-router-dom";
import Typography from '@mui/joy/Typography';
import { useEffect } from 'react';
import { useSearchParams } from "react-router-dom";

function capFirstLetter(word:string):string {
    return word.charAt(0).toUpperCase() + word.slice(1);
}

function reformatPathEnd(pathEnd:string):string {
    // Replace underscores with spaces and capitalize first letter of each word.
    return pathEnd.split("_").map(capFirstLetter).join(" ");
}


interface NoLinkCrumbProps {
    title: string;
}

export function NoLinkCrumb( { title }: NoLinkCrumbProps) {
    return (
        <Typography color="primary" fontWeight={500}>
        { title }
        </Typography>
    );
}

export default function OurBreadcrumbs() {
    const location = useLocation();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        // Not much we need to do here, right, just trigger a re-render.
      }, [location.pathname]);

    const path = window.location.pathname;
    const theCrumbs = [];
    const pathEnd = path.split("/").pop() || "";

    theCrumbs.push(<Link
        underline="none"
        color="neutral"
        to="/"
        aria-label="Home"
        key={theCrumbs.length}
        component={RouterLink}
            >
            <HomeRoundedIcon />
        </Link>
    );

    // Now add a bunch of crumbs for various pages.
    if (pathEnd === "report") {

        if (searchParams.get("type") === "singleseries") {
            theCrumbs.push(<Link
                underline="none"
                color="neutral"
                to="/single_series"
                aria-label="Single Series"
                key={theCrumbs.length}
                component={RouterLink}>
                    Single Series
                </Link>
            );
        }

        theCrumbs.push(<NoLinkCrumb key={theCrumbs.length} title="Report" />);
     }

    if (path.indexOf("session_detail") > -1) {
        theCrumbs.push(<Link
            underline="none"
            color="neutral"
            to="/sessions"
            aria-label="Sessions"
            key={theCrumbs.length}
            component={RouterLink}>
                Sessions
            </Link>
        );

        theCrumbs.push(<NoLinkCrumb key={theCrumbs.length} title={pathEnd} />);
    }

    if (path.indexOf("tablet_detail") > -1) {
        theCrumbs.push(<Link
            underline="none"
            color="neutral"
            to="/tablets"
            aria-label="Tablets"
            key={theCrumbs.length}
            component={RouterLink}>
                Tablets
            </Link>
        );

        theCrumbs.push(<NoLinkCrumb key={theCrumbs.length} title={pathEnd} />);
    }

    if (path.indexOf("explore_detail") > -1) {
        theCrumbs.push(<Link
            underline="none"
            color="neutral"
            to="/explore"
            aria-label="Explore"
            key={theCrumbs.length}
            component={RouterLink}>
                Explore
            </Link>
        );

        theCrumbs.push(<NoLinkCrumb key={theCrumbs.length} title={searchParams.get("key") || ""} />);
    }

    if (path.indexOf("clifford_overview") > -1) {
        theCrumbs.push(<NoLinkCrumb key={theCrumbs.length} title="Rabbit Study" />);
    }

    if (path === "/single_series" || path === "/sessions" || path === "/mats" || path === "/neurostimulators" || path === "/tablets" || path === "/explore") {
        theCrumbs.push(<NoLinkCrumb key={theCrumbs.length} title={ reformatPathEnd(pathEnd) } />);
    }

    return (
        <Breadcrumbs
            size="lg"
            aria-label="breadcrumbs"
            separator={<ChevronRightRoundedIcon fontSize="small" />}
            sx={{ pl: 0, paddingBottom: '1vh'}}
            >
            { theCrumbs }
        </Breadcrumbs>
    );
}