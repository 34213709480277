import * as React from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Divider from '@mui/joy/Divider';
import Input from '@mui/joy/Input';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import DatasetRoundedIcon from '@mui/icons-material/DatasetRounded';
import ExploreRoundedIcon from '@mui/icons-material/ExploreRounded';
import DevicesOtherRounded from '@mui/icons-material/DevicesOtherRounded';
import AssessmentRounded from '@mui/icons-material/AssessmentRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { useNavigate } from 'react-router-dom';
import ColorSchemeToggle from './ColorSchemeToggle';
import { closeSidebar } from '../utils';
import { userName } from '../OurAuthTokenWrapper';
import { userEmail } from '../OurAuthTokenWrapper';
import { userIcon } from '../OurAuthTokenWrapper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useRef } from 'react';
import { useSearchParams } from "react-router-dom";

function Toggler({
  defaultExpanded = false,
  renderToggle,
  children,
}: {
  defaultExpanded?: boolean;
  children: React.ReactNode;
  renderToggle: (params: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }) => React.ReactNode;
}) {
  const [open, setOpen] = React.useState(defaultExpanded);
  return (
    <React.Fragment>
      {renderToggle({ open, setOpen })}
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: open ? '1fr' : '0fr',
          transition: '0.2s ease',
          '& > *': {
            overflow: 'hidden',
          },
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
}

export default function Sidebar() {
    const [searchParams] = useSearchParams();
    const searchRef = useRef<HTMLInputElement>(null);
    const path = window.location.pathname;
    const navigate = useNavigate();
    const handleSettingsClick = () => {
        alert("Settings are not implemented yet. Maybe we'll come up with something to put there soon!");
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            const searchTerm = (event.target as HTMLInputElement).value;
            if (searchTerm.length > 0) {
                if (searchRef.current) {
                    searchRef.current.value = "";
                }

                navigate(`/sessions?metadata=${searchTerm}`);
            }
        }
    };

    const isInSection = (section: string): boolean => {
        // Pre-first: special case for home, since it will be an empty string
        // that will trip up the default section below.
        if (section === "") {
            return path === "/";
        }

        // First, handle obvious defaults where the page name matches the section name directly.
        if (path.startsWith("/" + section)) {
            return true;
        }

        // Now anything special, one if per section, check each of your pages:
        if (section === "sessions" && path.startsWith("/session_detail")) {
          return true;
        }

        if (section === "explore"  && path.startsWith("/expore_detail")) {
          return true;
        }

        // If we're in a report, then the section is defined by the type parameter, so check that instead.
        if (path.startsWith("/report") && section === "single_series" && searchParams.get("type") === "singleseries") {
          return true;
        }

        // Looks like we're not in this section, that's fine.
        return false;
    };

  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: { xs: 'fixed', md: 'sticky' },
        transform: {
          xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
          md: 'none',
        },
        transition: 'transform 0.4s, width 0.4s',
        zIndex: 10000,
        height: '100dvh',
        width: 'var(--Sidebar-width)',
        top: 0,
        p: 2,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRight: '1px solid',
        borderColor: 'divider',
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Sidebar-width': '220px',
            [theme.breakpoints.up('lg')]: {
              '--Sidebar-width': '240px',
            },
          },
        })}
      />
      <Box
        className="Sidebar-overlay"
        sx={{
          position: 'fixed',
          zIndex: 9998,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          opacity: 'var(--SideNavigation-slideIn)',
          backgroundColor: 'var(--joy-palette-background-backdrop)',
          transition: 'opacity 0.4s',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
            lg: 'translateX(-100%)',
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <Typography level="title-lg">Arcadia</Typography>
        <ColorSchemeToggle sx={{ ml: 'auto' }} />
      </Box>
      <Input size="sm" startDecorator={<SearchRoundedIcon />} placeholder="Search" onKeyDown={handleKeyDown} slotProps={{ input: { ref: searchRef } }} />
      <Box
        sx={{
          minHeight: 0,
          overflow: 'hidden auto',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        <List
          size="sm"
          sx={{
            gap: 1,
            '--List-nestedInsetStart': '30px',
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
          }}
        >
          <ListItem>
            <ListItemButton onClick={() => navigate("/")} selected={ isInSection("") }>
              <HomeRoundedIcon />
              <ListItemContent>
                <Typography level="title-sm">Home</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem nested>
            <Toggler
              renderToggle={({ open, setOpen }) => (
                <ListItemButton onClick={() => setOpen(!open)}>
                  <AssessmentRounded />
                  <ListItemContent>
                    <Typography level="title-sm">Reports</Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={{ transform: open ? 'rotate(180deg)' : 'none' }}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{ gap: 0.5 }}>
                <ListItem sx={{ mt: 0.5 }}>
                  <ListItemButton onClick={() => navigate("/single_series")} selected={ isInSection("single_series") }>Single Series</ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton onClick={() => navigate("/clifford_overview")} selected={ isInSection("clifford_overview") }>Rabbit Study</ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton onClick={() => navigate("/bug_reports")} selected={ isInSection("bug_reports") }>Midnight Bug Reports</ListItemButton>
                </ListItem>
              </List>
            </Toggler>
          </ListItem>

          <ListItem>
            <ListItemButton onClick={() => navigate("/sessions")} selected={ isInSection("sessions") }>
              <DatasetRoundedIcon />
              <ListItemContent>
                <Typography level="title-sm">Sessions</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem nested>
            <Toggler
              renderToggle={({ open, setOpen }) => (
                <ListItemButton onClick={() => setOpen(!open)}>
                  <DevicesOtherRounded />
                  <ListItemContent>
                    <Typography level="title-sm">Devices</Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={{ transform: open ? 'rotate(180deg)' : 'none' }}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{ gap: 0.5 }}>
                <ListItem sx={{ mt: 0.5 }}>
                  <ListItemButton onClick={() => navigate("/tablets")} selected={ isInSection("tablets") }>Tablets</ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton onClick={() => navigate("/mats")} selected={ isInSection("mats") }>Mats</ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton onClick={() => navigate("/neurostimulators")} selected={ isInSection("neurostimulators") }>Neurostimulators</ListItemButton>
                </ListItem>
              </List>
            </Toggler>
          </ListItem>

          <ListItem>
            <ListItemButton onClick={() => navigate("/explore")} selected={ isInSection("explore") }>
              <ExploreRoundedIcon />
              <ListItemContent>
                <Typography level="title-sm">Explore</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

        </List>

        <List
          size="sm"
          sx={{
            mt: 'auto',
            flexGrow: 0,
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
            '--List-gap': '8px',
            mb: 2,
          }}
        >
          <ListItem>
            <ListItemButton onClick={handleSettingsClick}>
              <SettingsRoundedIcon />
              Settings
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
      <Divider />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
      <Avatar
          variant="outlined"
          size="sm"
          src={userIcon.value}
        />
        <Box sx={{ minWidth: 0, flex: 1 }}>
          <Typography level="title-sm">{userName}</Typography>
          <Typography level="body-xs" noWrap>{userEmail}</Typography>
        </Box>
      </Box>
    </Sheet>
  );
}
