import { isDevMode } from '../index';
import * as Constants from '../Constants';
import { emptyIfNull } from '../utils';

// Singleton class to manage accessing our backend.
//

export class NetworkHelper {
    private readonly NOISY: boolean = true;
    accessToken: string = "";
    private static instance: NetworkHelper;
    controller = new AbortController();

    private constructor() {
        console.log('NetworkHelper created');
    }

    public static getInstance(): NetworkHelper {
        if (!NetworkHelper.instance) {
            console.warn("NetworkHelper instance not created yet, creating now");
            NetworkHelper.instance = new NetworkHelper()
        }

        return NetworkHelper.instance
    }

    //////////////////////////////////////////////////////////////////////////////////////////////

    // Most methods take a reference to a NavigationFunction, which is used to redirect to the
    // login page if 401'd (no session/expired session).

    //////////////////////////////////////////////////////////////////////////////////////////////

    public setAccessToken(accessToken:string) {
        this.accessToken = accessToken;
    }

    public hasAccessToken(): boolean {
        return this.accessToken !== "";
    }

//    public async doLoginMSAL(accessToken:string): Promise<any> {
//        return this.doBackendRequest("login", { "msal_token": accessToken });
//    }

    public async doAuthPing(): Promise<any> {
        return await this.doBackendRequest("auth_ping", null);
    }

    public async doGetTablets(): Promise<any> {
        const response = await this.doBackendRequest("get_tablets", null);

        if (response && response.success) {
            return response.result;
        }
        else {
            if (response && response.message) {
                console.error(`doGetTablets received non-success from backend, message: ${response.message}`);
            }
            return null;
        }
    }
    
    public async doGetTablet(serial_number:string): Promise<any> {
        const response = await this.doBackendRequest(`get_tablet?serial=${serial_number}`, null);

        if (response && response.success) {
            return response.result;
        }
        else {
            if (response && response.message) {
                console.error(`doGetTablet received non-success from backend, message: ${response.message}`);
            }
            return null;
        }
    }

    public async doGetDeviceLog(tablet_id:number): Promise<any> {
        const response = await this.doBackendRequest(`get_device_log?tablet_id=${tablet_id}`, null);

        if (response && response.success) {
            return response.result;
        }
        else {
            if (response && response.message) {
                console.error(`doGetDeviceLog received non-success from backend, message: ${response.message}`);
            }
            return null;
        }
    }
    public async doGetClinics(): Promise<any> {
        const response = await this.doBackendRequest(`get_clinics`, null);

        if (response && response.success) {
            return response.result;
        }
        else {
            if (response && response.message) {
                console.error(`doGetClinics received non-success from backend, message: ${response.message}`);
            }
            return null;
        }
    }

    public async doGetBugReports(): Promise<any> {
        const response = await this.doBackendRequest("get_bug_reports", null);

        if (response && response.success) {
            return response.result;
        }
        else {
            if (response && response.message) {
                console.error(`doGetBugReports received non-success from backend, message: ${response.message}`);
            }
            return null;
        }
    }

    public async doSearchSessions(searchParams: object): Promise<any> {
        const response = await this.doBackendRequest("search_sessions", searchParams);

        if (response && response.success) {
            return response.result;
        }
        else {
            if (response && response.message) {
                console.error(`doGetRecentSessions received non-success from backend, message: ${response.message}`);
            }
            return null;
        }
    }

    public async doGetRecentSessions(): Promise<any> {
        const response = await this.doBackendRequest("get_recent_programmer_sessions", null);

        if (response && response.success) {
            return response.result;
        }
        else {
            if (response && response.message) {
                console.error(`doGetRecentSessions received non-success from backend, message: ${response.message}`);
            }
            return null;
        }
    }

    public async doGetUniqueMetadata(): Promise<any> {
        const response = await this.doBackendRequest("get_unique_metadata", null);

        if (response && response.success) {
            return response.result;
        }
        else {
            if (response && response.message) {
                console.error(`doGetUniqueMetadata received non-success from backend, message: ${response.message}`);
            }
            return null;
        }
    }

    public async doGetTimeSeriesData(mdKey: string, mdValue: string, seriesId: number, startDate: string | null, endDate: string | null, sessionID: string | null): Promise<any> {
        var queryString = `md_key=${mdKey}&md_value=${mdValue}&series_id=${seriesId}&start_date=${emptyIfNull(startDate)}&end_date=${emptyIfNull(endDate)}&session_id=${emptyIfNull(sessionID)}`;
        const response = await this.doBackendRequest(`get_time_series_data?${queryString}`, null);

        if (response && response.success) {
            return response.result;
        }
        else {
            if (response && response.message) {
                console.error(`doGetTimeSeriesData received non-success from backend, message: ${response.message}`);
            }
            return null;
        }
    }

    public async doAddTablet(serial_number: string): Promise<boolean> {
        const response = await this.doBackendRequest("add_tablet", { "serial": serial_number });

        if (response && response.success) {
            return response;
        }
        else {
            if (response && response.message) {
                console.error(`doAddTablet received non-success from backend, message: ${response.message}`);
            }
            return response;
        }
    }
    
    public async doSetTabletClinic(serial_number: string, clinicID: number): Promise<boolean> {
        const response = await this.doBackendRequest("set_tablet_clinic", { "serial_number": serial_number, "clinic_id": clinicID});

        if (response && response.success) {
            return true;
        }
        else {
            if (response && response.message) {
                console.error(`doSetTabletClinic received non-success from backend, message: ${response.message}`);
            }
            return false;
        }
    }

    public async doUpdateImplantNotes(serial_number: string, notes: string): Promise<boolean> {
        const response = await this.doBackendRequest("set_implant_notes", { "serial_number": serial_number, "notes": notes});

        if (response && response.success) {
            return true;
        }
        else {
            if (response && response.message) {
                console.error(`doUpdateImplantNotes received non-success from backend, message: ${response.message}`);
            }
            return false;
        }
    }

    public async doGetImplants(): Promise<any> {
        const response = await this.doBackendRequest("get_implants", null);

        if (response && response.success) {
            return response.result;
        }
        else {
            if (response && response.message) {
                console.error(`doGetImplants received non-success from backend, message: ${response.message}`);
            }
            return null;
        }
    }

    public async doGetCliffordSummary(): Promise<any> {
        const response = await this.doBackendRequest("get_clifford_summary", null);

        if (response && response.success) {
            return response.result;
        }
        else {
            if (response && response.message) {
                console.error(`doGetCliffordSummary received non-success from backend, message: ${response.message}`);
            }
            return null;
        }
    }

    public async doGetTimeSeriesNames(): Promise<any> {
        const response = await this.doBackendRequest("get_time_series_names", null);

        if (response && response.success) {
            return response.result;
        }
        else {
            if (response && response.message) {
                console.error(`doGetTimeSeriesNames received non-success from backend, message: ${response.message}`);
            }
            return null;
        }
    }

    public async doGetMetadataDetail(key:string): Promise<any> {
        const response = await this.doBackendRequest(`get_metadata_detail?key=${key}`, null);

        if (response && response.success) {
            return response.result;
        }
        else {
            if (response && response.message) {
                console.error(`doGetMetadataDetail received non-success from backend, message: ${response.message}`);
            }
            return null;
        }
    }

    public async doGetRecentGenericSessions(): Promise<any> {
        const response = await this.doBackendRequest("get_recent_generic_sessions", null);

        if (response && response.success) {
            return response.result;
        }
        else {
            if (response && response.message) {
                console.error(`doGetRecentGenericSessions received non-success from backend, message: ${response.message}`);
            }
            return null;
        }
    }

    public async doGetSessionSummary(id:number): Promise<any> {
        const response = await this.doBackendRequest(`get_session_summary?id=${id}`, null);

        if (response && response.success && response.rows > 0) {
            return response;
        }
        else {
            if (response && response.message) {
                console.error(`doGetSessionSummary received non-success from backend, message: ${response.message}`);
            }
            return null;
        }
    }
    //////////////////////////////////////////////////////////////////////////////////////////////

    public getBackendURLStem(): string {
        return isDevMode ? Constants.REST_API_URL_DEV : Constants.REST_API_URL_PROD;
    }

    public abort() {
        this.controller.abort();
        this.controller = new AbortController();
    }

    async doBackendRequest(service:string, payload: any): Promise<any> {
        const URL = (isDevMode ? Constants.REST_API_URL_DEV : Constants.REST_API_URL_PROD) + service;
        const verb = payload ? "POST" : "GET";
        this.controller = new AbortController();

        if (this.NOISY) {
            console.log("%s: %s, payload: %s", verb, URL, JSON.stringify(payload));
        }

        const headers:any = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        if (this.accessToken) {
            headers["Authorization"] = "Bearer " + this.accessToken;
        }
        else {
            console.log(`firing request without access token, oh no!`);
        }

        const params:RequestInit = {
            credentials: 'include',
            mode: 'cors',
            method: verb,
            headers: headers,
            signal: this.controller.signal
        };

        if (payload) {
            params.body = JSON.stringify(payload);
        }


        try {
            const response = await fetch(URL, params);

            if (this.NOISY && response.status !== 200) {
                console.warn(`response status: ${response.status}`);
            }

            if (response.status === 401) {
                // TODO: can we infer the current page and pass it to login so that it
                // redirects back to the proper page after login?
                console.warn("401, we should figure out how to handle this!");
                return null;
                //nav(Constants.APP_LOGIN_PAGE);
            }

            const contentType = response.headers.get("content-type");

            if (contentType && contentType.indexOf("application/json") !== -1) {
                const json = await response.json();
                return json;
            }
            else {
                console.error("non-JSON response from server, this is not good (status = %d)", response.status);
                const text = await response.text();
                console.error("response: " + text);
                return null;
            }
        }
        catch (e) {
            if (typeof e === 'object' && e !== null && 'name' in e && 'message' in e) {
                // Don't complain about aborts, they are expected.
                if (e.name !== "AbortError") {
                    console.error(`Network error: name=${e.name}, message=${e.message}`);
                    throw e;
                }
            }

            return null;
        }
    }
}