import { getEvenRowTableBackground, getOddRowTableBackground } from '../utils';
import { useColorScheme } from '@mui/joy/styles';
import Typography from '@mui/joy/Typography';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Link from '@mui/joy/Link';
import { Link as RouterLink } from "react-router-dom";

type StatsEntry = {
    series: number;              // Series ID
    name: string;                  // Name of the stat, such as "ImplantPeriSignal"
    sampleCount: number;           // Number of samples in this bucket
    unit: string;                  // Unit of the value, such as "mV" or "dBm"
    sum: number;                   // Sum of all values
    min: number;                   // Minimum value
    max: number;                   // Maximum value
    avg: number;                   // Average value
};

interface StatsTableProps {
    session: number;               // Session ID
    stats: StatsEntry[];
}

export default function MetadataTable(props:StatsTableProps) {
    const { mode } = useColorScheme();
    const rowEvenBackground = getEvenRowTableBackground(mode!);
    const rowOddBackground = getOddRowTableBackground(mode!);

    return (
        <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
            width: '100%',
            borderRadius: '1px',
            borderWidth: '1px',
            flexShrink: 0,
            minHeight: 0,
        }}>
            <Table
                aria-labelledby="tableTitle"
                sx={{
                    '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                    '--Table-headerUnderlineThickness': '1px',
                    '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                    '--TableCell-paddingY': '4px',
                    '--TableCell-paddingX': '8px',
                }}
            >
            <thead>
                <tr>
                    <th style={{ width: 40, padding: '12px 6px' }}>Name</th>
                    <th style={{ width: 20, padding: '12px 6px' }}>Samples</th>
                    <th style={{ width: 20, padding: '12px 6px' }}>Min</th>
                    <th style={{ width: 20, padding: '12px 6px' }}>Max</th>
                    <th style={{ width: 20, padding: '12px 6px' }}>Avg</th>
                </tr>
            </thead>
            <tbody>
            {props.stats.map((row:any, index:number) => (
                    <tr style ={ index % 2? { background : rowEvenBackground }:{ background : rowOddBackground }} key={index}>
                        <td>
                            <Typography level="body-sm"><Link to={`/report?type=singleseries&series=${row.series}&session=${props.session}`} component={RouterLink}>{row.name}</Link></Typography>
                        </td>
                        <td>
                            <Typography level="body-sm">{ row.sampleCount }</Typography>
                        </td>
                        <td>
                            <Typography level="body-sm">{ `${row.min} ${row.unit}` }</Typography>
                        </td>
                        <td>
                            <Typography level="body-sm">{ `${row.max} ${row.unit}` }</Typography>
                        </td>
                        <td>
                            <Typography level="body-sm">{ `${Math.round(row.avg)} ${row.unit}` }</Typography>
                        </td>
                    </tr>
                    ))}
            </tbody>
            </Table>
        </Sheet>
    );
}