import Typography from '@mui/joy/Typography';
import { NetworkHelper } from '../util/NetworkHelper';
import { useQuery } from '@tanstack/react-query';
import { Sheet, useColorScheme } from '@mui/joy';
import Table from '@mui/joy/Table';
import { getEvenRowTableBackground, getOddRowTableBackground, localizeDate } from '../utils';

interface CliffordTSVRow {
    serial_number: string,
    series_id: number,
    time_stamp: string,
    series_avg: number
}

interface OverviewChartProps {
    serial_number: string,
    time_series_values: CliffordTSVRow[]
}

//
// TODO: replace this with proper charting from joins when we have time
//
// Note: unit for finalvpp from database is mV, unit for bestfreq is Hz
function getLastRowDescription(series_id: number, series_name: string, unit: string, time_series_values:CliffordTSVRow[]) {
    var description = `No sample for ${series_name}`;

    for (const row of time_series_values) {
        if (row.series_id === series_id) {
            var theValue = row.series_avg;

            // Temporary hacks to convert down from raw (mV, Hz) units to more human-readable ones.
            if (series_id === 37) {
                theValue = theValue / 1000;
            }

            if (series_id === 42) {
                theValue = theValue / 1000000;
            }

            const date = new Date(row.time_stamp);
            description = `${date.toLocaleDateString()}: ${series_name} = ${theValue}${unit}`;
        }
    }

    return description;
}


export function OverviewChart(props:OverviewChartProps) {
    // console.log(`rendering an overview chart, serial_number: ${props.serial_number}, time_series_values: ${props.time_series_values.length}`)
    return (
        <>
        { getLastRowDescription(37, "FinalVPP", "V", props.time_series_values) }<br/>
        { getLastRowDescription(42, "BestFreq", "MHz", props.time_series_values) }
        </>
    );
}

export default function CliffordOverview() {
    const summaryQuery = useQuery({
        queryKey: ['getCliffordSummary'],
        queryFn: () => NetworkHelper.getInstance().doGetCliffordSummary()
    });

    // TODO: figure out a better way to reuse this alternative row logic without
    // copying it every time
    const { mode } = useColorScheme();
    const rowEvenBackground = getEvenRowTableBackground(mode!);
    const rowOddBackground = getOddRowTableBackground(mode!);

    if (summaryQuery.isError) {
        return <p>Error fetching rabbit study overview: {summaryQuery.error.message}</p>;
    }

    if (!summaryQuery.isSuccess) {
        return <p>Loading...</p>;
    }
    else {
        return (
        <>
        <Typography level="h2" component="h1">Rabbit Study Overview</Typography>
        <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
            width: '100%',
            borderRadius: '1px',
            borderWidth: '1px',
            flexShrink: 0,
            overflow: 'auto',
            minHeight: 0,
            marginTop: '16px'
        }}
        >
        <Table
            aria-labelledby="tableTitle"
            stickyHeader
            hoverRow
            sx={{
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
            '--TableCell-paddingY': '4px',
            '--TableCell-paddingX': '8px',
            }}
        >
            <thead>
                <tr>
                    <th style={{ width: "10%", padding: '12px 6px' }}>Serial</th>
                    <th style={{ width: "10%", padding: '12px 6px' }}>Last Seen</th>
                    <th style={{ width: "30%", padding: '12px 6px' }}>Notes</th>
                    <th style={{ width: "50%", padding: '12px 6px' }}>Chart</th>
                </tr>
            </thead>
            <tbody>
            {summaryQuery.data.implants.map((row:any, index:number) => (
                <tr style ={ index % 2? { background : rowEvenBackground }:{ background : rowOddBackground }} key={row.serial_number}>
                    <td>
                        <Typography level="body-sm">{row.serial_number}</Typography>
                    </td>
                    <td>
                        <Typography level="body-sm">{localizeDate(row.last_seen)}</Typography>
                    </td>
                    <td>
                        <Typography level="body-sm">{row.notes}</Typography>
                    </td>
                    <td>
                        <OverviewChart serial_number={row.serial_number} time_series_values={summaryQuery.data.tsv.filter((tsv_row:CliffordTSVRow) => tsv_row.serial_number === row.serial_number)}/>
                    </td>
                </tr>
            ))}
            </tbody>
        </Table>
        </Sheet>
        </>
    );
    }
}

