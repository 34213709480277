import { NetworkHelper } from '../util/NetworkHelper';
import { useState, useEffect } from 'react';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import { getEvenRowTableBackground, getOddRowTableBackground } from '../utils';
import Link from '@mui/joy/Link';
import { Link as RouterLink } from "react-router-dom";
import { useColorScheme } from '@mui/joy/styles';
import { useSearchParams } from "react-router-dom";

export default function SessionDetail() {
    const [searchParams] = useSearchParams();
    const net = NetworkHelper.getInstance();
    const [keySummary, setKeySummary] = useState<any>(null);
    const { mode } = useColorScheme();
    const rowEvenBackground = getEvenRowTableBackground(mode!);
    const rowOddBackground = getOddRowTableBackground(mode!);

    useEffect(() => {
      document.title = `Arcadia - Explore: ${searchParams.get("key")}`;
    }, [searchParams]);

    useEffect(() => {
        let cancelMe = false;

        const doAsyncWork = async () => {
            const result = await net.doGetMetadataDetail(searchParams.get("key")!);

            if (!cancelMe) {
                if (!result) {
                    console.log("Session summary never came back, that's unfortunate");
                }

                setKeySummary(result);
            }
        }

        doAsyncWork().catch((error) => console.error(error));

        return () => {
            net.abort();
            cancelMe = true;
        }
    }, [searchParams, net]);

    if (keySummary) {
        return (
            <>
            <Typography level="h2" component="h1">Explore: {searchParams.get("key")}</Typography>
            <span>
                This is a list of all distinct <u>values</u> for the metadata key "{searchParams.get("key")}". To search for sessions
                with a specific <u>value</u>, click the link in the "Sessions" column.
            </span>
            <Sheet
            className="OrderTableContainer"
            variant="outlined"
            sx={{
              display: { xs: 'none', sm: 'initial' },
              width: '100%',
              borderRadius: '1px',
              borderWidth: '1px',
              flexShrink: 1,
              overflow: 'auto',
              minHeight: 0,
              marginTop: '16px'
            }}
          >
            <Table
              aria-labelledby="tableTitle"
              stickyHeader
              hoverRow
              sx={{
                '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                '--Table-headerUnderlineThickness': '1px',
                '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                '--TableCell-paddingY': '4px',
                '--TableCell-paddingX': '8px',
              }}
            >
                <thead>
                    <tr>
                        <th style={{ width: 80, padding: '12px 6px' }}>Value</th>
                        <th style={{ width: 50, padding: '12px 6px' }}>Sessions</th>
                    </tr>
                </thead>
                <tbody>
                {keySummary.map((row:any, index:number) => (
                  <tr style ={ index % 2? { background : rowEvenBackground }:{ background : rowOddBackground }} key={row.id}>
                    <td>
                      <Typography level="body-sm">{row.value}</Typography>
                    </td>
                    <td>
                        <Link to={`/sessions?metadata=${searchParams.get("key")}%3D${row.value}`} component={RouterLink}>{row.session_count}</Link>
                    </td>
                  </tr>
                ))}
                </tbody>
            </Table>
            </Sheet>
            </>
        );
    }
    else {
        return <p>Fetching values for metadata key...</p>
    }
}
