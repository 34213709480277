import { useState, useEffect, useMemo } from 'react';
import { NetworkHelper } from '../util/NetworkHelper';
import Input from '@mui/joy/Input';
import Link from '@mui/joy/Link';
import { Link as RouterLink } from "react-router-dom";
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import { useColorScheme } from '@mui/joy/styles';
import { getEvenRowTableBackground, getOddRowTableBackground, localizeDate, metadataHasSelfTestFailure } from '../utils';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import SearchIcon from '@mui/icons-material/Search';
import { useRef } from 'react';
import { findMetadataValue, MetadataRow, pickColorByMode } from '../utils';
import { useSearchParams } from "react-router-dom";

// containsHiddenMetadata returns true if this list of metadata
// contains a matching value that would not be shown in the default
// display of PatientID, IMSerial, and MatSerial. This is used to
// present and populate the "Notes" column if someone searches for
// something that would not normally appear in the results.
function containsHiddenMetadata(metadata: MetadataRow[], value: string | null): boolean {
    if (!value) {
        return false;
    }
    for (const row of metadata) {
        if (row.value.toUpperCase() === value.toUpperCase()) {
            if (row.key !== "PatientID" && row.key !== "IMSerial" && row.key !== "MatSerial") {
                return true;
            }
        }
    }

    // Guess we're ok.
    return false;
}

interface NotesDescriptionProps {
    metadata: MetadataRow[];
    hitValue: string | null;
}

function NotesDescription( { metadata, hitValue }: NotesDescriptionProps) {
    const filteredMetadata = useMemo(() => {
        const isInterestingMetadata = (row: MetadataRow) => {
            return hitValue && row.value.toUpperCase() === hitValue.toUpperCase() &&
                   (row.key !== "PatientID" && row.key !== "IMSerial" && row.key !== "MatSerial");
        }

        return metadata.filter(isInterestingMetadata);
    }, [metadata, hitValue]);

    return (
        <>
            { filteredMetadata.map((row:MetadataRow, index:number) => (
                <span>{row.key}={row.value} &nbsp; </span>
            ))}
        </>
    );
}

function stripPrefix(value: string | null): string {
    if (!value) {
        return "";
    }

    if (value.indexOf("=") !== -1) {
        return value.split("=")[1];
    }
    else {
        return value;
    }
}



export default function Sessions() {
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const metadataRef = useRef<HTMLInputElement>(null);      // Refs to input field in HTML form
    const startDateRef = useRef<HTMLInputElement>(null);
    const endDateRef = useRef<HTMLInputElement>(null);
    const net = NetworkHelper.getInstance();
    const [sessionsList, setSessionsList] = useState<any[]>([]);
    const { mode } = useColorScheme();

    const rowEvenBackground = getEvenRowTableBackground(mode!);
    const rowOddBackground = getOddRowTableBackground(mode!);
    const rowFailureBackground = pickColorByMode(mode!, "#ffc0c0", "#400000");

    useEffect(() => {
        document.title = "Arcadia - Sessions";
    }, []);

    const guessBackgroundColor = (row:any, index:number): string => {
        if (metadataHasSelfTestFailure(row.metadata)) {
            return rowFailureBackground;
        }
        else {
            return index % 2 ? rowEvenBackground : rowOddBackground;
        }
    }

    const hasNotesColumn = ():boolean => {
        // No results, no need for a notes column.
        if (sessionsList.length === 0) {
            return false;
        }

        // Go through each session and see if there's metadata that won't be shown.
        for (const session of sessionsList) {
            if (containsHiddenMetadata(session.metadata, stripPrefix(searchParams.get("metadata")))) {
                return true;
            }
        }

        // No hidden metadata found.
        return false;
    }

    // Don't hammer this on every row render.
    const needNotesColumn = useMemo(hasNotesColumn, [sessionsList, searchParams]);

    const fireSearch = () => {
        const newParams:any = { };

        // Only assign props where a value was entered to keep the URL tidy.

        if (metadataRef.current!.value) {
            newParams.metadata = metadataRef.current!.value;
        }

        if (startDateRef.current!.value) {
            newParams.start_date = startDateRef.current!.value;
        }

        if (endDateRef.current!.value) {
            newParams.end_date = endDateRef.current!.value;
        }

        setSearchParams(newParams);
    };

    const fireSearchIfEnterPressed = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            fireSearch();
        }
    };

    // searchParams changed, so we need to go get the data.
    useEffect(() => {
        let cancelMe = false;

        // We don't have any fields, so we can't do anything.
        if (!(metadataRef.current && startDateRef.current && endDateRef.current)) {
            return;
        }

        metadataRef.current.value = searchParams.get("metadata") || "";
        startDateRef.current.value = searchParams.get("start_date") || "";
        endDateRef.current.value = searchParams.get("end_date") || "";

        const doAsyncWork = async () => {
            setLoading(true);
            var result = await net.doSearchSessions( { "metadata": metadataRef.current!.value,
                                                       "start_date": startDateRef.current!.value,
                                                       "end_date": endDateRef.current!.value } );

            if (!cancelMe) {
                if (!result) {
                    console.log("Sessions list never came back, that's unfortunate");
                    setLoading(false);
                    return;
                }

                setSessionsList(result);
                setLoading(false);
            }
        }

        doAsyncWork().catch((error) => console.error(error));

        return () => {
            net.abort();
            cancelMe = true;
        }
    }, [net, searchParams]);

    return (
    <>
        <Typography level="h2" component="h1">Sessions</Typography>
        <Box
            className="SearchAndFilters-tabletUp"
            sx={{
                borderRadius: '1px',
                borderWidth: '1px',
                py: 2,
                display: 'flex',
                flexWrap: 'wrap',
                gap: 1.5,
                '& > *': {
                minWidth: { xs: '120px', md: '160px' },
                },
            }}
            >
            <FormControl sx={{ flex: 1 }} size="sm">
                <FormLabel>Metadata</FormLabel>
                <Input placeholder="Value" size="sm" type="text" id="metadata" onKeyDown={fireSearchIfEnterPressed} slotProps={{ input: { ref: metadataRef } }}  />
            </FormControl>
            <FormControl sx={{ flex: 0 }} size="sm">
                <FormLabel>Start date</FormLabel>
                <Input size="sm" type="date" id="startDate" slotProps={{ input: { ref: startDateRef } }} />
            </FormControl>
            <FormControl sx={{ flex: 0 }} size="sm">
                <FormLabel>End date</FormLabel>
                <Input size="sm" type="date" id="endDate" slotProps={{ input: { ref: endDateRef } }} />
            </FormControl>
            <Button
                onClick={fireSearch}
                type="submit"
                color="primary"
                startDecorator={<SearchIcon />}
                size="sm"
                sx={{ height: '32px', alignSelf: 'flex-end' }}
                >
                Search
            </Button>
            </Box>

        <Sheet
    className="OrderTableContainer"
    variant="outlined"
    sx={{
        display: 'initial',
        width: '100%',
        borderRadius: '1px',
        borderWidth: '1px',
        flexShrink: 1,
        minHeight: 0,
    }}
    >
    <Table
        aria-labelledby="tableTitle"
        stickyHeader
        hoverRow
        sx={{
        '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
        '--Table-headerUnderlineThickness': '1px',
        '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
        '--TableCell-paddingY': '4px',
        '--TableCell-paddingX': '8px',
        }}
    >
        <thead>
            <tr>
                <th style={{ width: 20, padding: '12px 6px' }}>ID</th>
                <th style={{ width: 20, padding: '12px 6px' }}>Source</th>
                <th style={{ width: 40, padding: '12px 6px' }}>PatientID</th>
                <th style={{ width: 40, padding: '12px 6px' }}>Started</th>
                <th style={{ width: 30, padding: '12px 6px' }}>Duration</th>
                <th style={{ width: 40, padding: '12px 6px' }}>Mat</th>
                <th style={{ width: 40, padding: '12px 6px' }}>Implant</th>
                { needNotesColumn ?
                <th style={{ width: 80, padding: '12px 6px' }}>Notes</th>
                :
                ""
                }
            </tr>
        </thead>
        <tbody>
        { loading ? <tr><td colSpan={7}><Typography level="body-sm">Loading...</Typography></td></tr> :
            sessionsList.map((row:any, index:number) => (
                <tr style={{ background: guessBackgroundColor(row, index) }} key={index}>
                <td>
                    <Link to={`/session_detail/${row.id}`} component={RouterLink}>{row.id}</Link>
                </td>
                <td>
                    <Typography level="body-sm">{row.source}</Typography>
                </td>
                <td>
                    <Typography level="body-sm">{ findMetadataValue(row.metadata, "PatientID") }</Typography>
                </td>
                <td>
                    <Typography level="body-sm">{localizeDate(row.first_packet_date)}</Typography>
                </td>
                <td>
                    <Typography level="body-sm">{row.duration}</Typography>
                </td>
                <td>
                    <Typography level="body-sm">{ findMetadataValue(row.metadata, "MatSerial") }</Typography>
                </td>
                <td>
                    <Typography level="body-sm">{ findMetadataValue(row.metadata, "IMSerial") }</Typography>
                </td>
                { needNotesColumn ?
                <td>
                    <Typography level="body-sm"><NotesDescription metadata={row.metadata} hitValue={stripPrefix(searchParams.get("metadata"))} /></Typography>
                </td>
                :
                ""
                }
                </tr>
            ))}
        </tbody>
    </Table>
    <br />
    </Sheet>
    </>
    );
}
