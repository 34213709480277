import { useState, useEffect } from 'react';
import { NetworkHelper } from '../util/NetworkHelper';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import { useColorScheme } from '@mui/joy/styles';
import { getEvenRowTableBackground, getOddRowTableBackground, localizeDate } from '../utils';
import Link from '@mui/joy/Link';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Button, FormControl, FormLabel, Input } from '@mui/joy';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';

interface AddTabletForm {
  serialNumber: string | null
}

export default function Tablets() {
    const navigate = useNavigate();
    const net = NetworkHelper.getInstance();
    const [tabletsList, setTabletsList] = useState<any[]>([]);
    const { mode } = useColorScheme();
    const rowEvenBackground = getEvenRowTableBackground(mode!);
    const rowOddBackground = getOddRowTableBackground(mode!);

    const [addTabletVisible, setAddTabletVisible] = useState(false);
    const [addTabletFailed, setAddTabletFailed] = useState(false);

    const addTabletMutation = useMutation({
        mutationFn: (options: any) => NetworkHelper.getInstance().doAddTablet(options.serial_number),
        onSuccess: (result:any) => {
            console.warn(`Tablet mutation done, result: ${result}`);
            if (result.success) {
                const newSerial = result.serial;
                navigate(`/tablet_detail/${newSerial}`);
            }
            else {
                setAddTabletFailed(true);
            }
        }
    });

    const {
        register,
        control,
        handleSubmit
    } = useForm<AddTabletForm>(({
        defaultValues: {
              serialNumber: ""
        },
    }));

    useEffect(() => {
        document.title = "Arcadia - Tablets";
    }, []);

    // columns: serial number (link to detail), android version, session count (link to metadata search), insert date
    useEffect(() => {
        let cancelMe = false;

        const doAsyncWork = async () => {
            const result = await net.doGetTablets();

            if (!cancelMe) {
                setTabletsList(result);
            }
        }

        doAsyncWork().catch((error) => console.error(error));

        return () => {
            cancelMe = true;
        }
    }, [net]);

    const onSubmit: SubmitHandler<AddTabletForm> = (add_tablet_data) => {
        setAddTabletFailed(false);

        if (add_tablet_data.serialNumber?.length ?? 0 > 5) {
            console.log(`Adding tablet with serial number: ${add_tablet_data.serialNumber}`);
            addTabletMutation.mutate({ serial_number: add_tablet_data.serialNumber });
        }
        else {
            console.warn(`Serial number sanity check too short: ${add_tablet_data.serialNumber}`);
        }
    }

    const fireSubmitIfEnterPressed = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleSubmit(onSubmit);
        }
    };    

    if (tabletsList) {
        return (
        <>
        <Typography level="h2" component="h1">Tablets</Typography>
        <Button
            type="submit"
            color="primary"
            startDecorator={<AddCircleIcon />}
            size="sm"
            sx={{ height: '32px', width: '200px', display: addTabletVisible ? "none" : "flex" }}
            onClick={ () => {
                setAddTabletFailed(false);
                setAddTabletVisible(true) }
            }
            >
            New Tablet
        </Button>

        <Typography
            level="body-md"
            sx={{  color: "red", display: (addTabletVisible && addTabletFailed) ? "block" : "none" }}
        >
        Failed to add tablet - is there already one with that serial number?
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
            <div
            style={{
              display: addTabletVisible ? "flex" : "none",
              alignItems: 'flex-end',
              flexShrink: 0,
              overflow: 'hidden',
              minHeight: 0,
              marginTop: '16px'
            }}>
                <FormControl size="sm">
                    <FormLabel>Serial Number*</FormLabel>
                    <Input placeholder="Serial"
                            size="sm"
                            type="text"
                            id="serialNumber"
                            onKeyDown={fireSubmitIfEnterPressed}
                            {...register("serialNumber", { required: true })} />
                </FormControl>            
                &nbsp; &nbsp; &nbsp; 
                <Button
                    type="submit"
                    color="primary"
                    startDecorator={<AddCircleIcon />}
                    size="sm"
                    sx={{ height: '32px', width: '120px'}}
                    >
                    Add
                </Button>
                &nbsp; &nbsp; &nbsp; 
                <Button
                        type="submit"
                        color="danger"
                        startDecorator={<CancelIcon />}
                        size="sm"
                        sx={{ height: '32px', width: '120px' }}
                        onClick={ () => setAddTabletVisible(false) }
                        >
                        Cancel
                </Button>
            </div>
        </form>

        <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: 'none', sm: 'initial' },
          width: '100%',
          borderRadius: '1px',
          borderWidth: '1px',
          flexShrink: 0,
          overflow: 'auto',
          minHeight: 0,
          marginTop: '16px'
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
            '--TableCell-paddingY': '4px',
            '--TableCell-paddingX': '8px',
          }}
        >
            <thead>
                <tr>
                    <th style={{ width: 10, padding: '12px 6px' }}>Serial</th>
                    <th style={{ width: 10, padding: '12px 6px' }}>Mnemonic</th>
                    <th style={{ width: 1, padding: '12px 6px' }}>Sessions</th>
                    <th style={{ width: 10, padding: '12px 6px' }}>Programmer Version</th>
                    <th style={{ width: 1, padding: '12px 6px' }}>Android Version</th>
                    <th style={{ width: 10, padding: '12px 6px' }}>Clinic</th>
                    <th style={{ width: 20, padding: '12px 6px' }}>Insert Date</th>
                </tr>
            </thead>
            <tbody>
            {tabletsList.map((row:any, index:number) => (
              <tr style ={ index % 2? { background : rowEvenBackground }:{ background : rowOddBackground }} key={row.id}>
                <td>
                <Link to={`/tablet_detail/${row.serial_number}`} component={RouterLink}>{row.serial_number}</Link>
                </td>
                <td>
                  <Typography level="body-sm">{row.mnemonic}</Typography>
                </td>
                <td>
                  { row.session_count === 0 ?
                  <Typography level="body-sm">{row.session_count}</Typography>
                  :
                  <Link to={`/sessions?metadata=${row.serial_number}`} component={RouterLink}>{row.session_count}</Link>
                  }
                </td>
                <td>
                  <Typography level="body-sm">{row.programmer_version}</Typography>
                </td>
                <td>
                  <Typography level="body-sm">{row.android_version}</Typography>
                </td>
                <td>
                  <Typography level="body-sm">{row.clinic_name}</Typography>
                </td>
                <td>
                  <Typography level="body-sm">{localizeDate(row.insert_date)}</Typography>
                </td>
              </tr>
            ))}
            </tbody>
        </Table>
        </Sheet>
        </>
        );
    }
    else {
        return <p>Fetching tablets list...</p>
    }
}
