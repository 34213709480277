import { useState, useEffect } from 'react';
import { NetworkHelper } from '../util/NetworkHelper';
import { DateTime} from 'luxon';
import Link from '@mui/joy/Link';
import { Link as RouterLink } from "react-router-dom";

import Typography from '@mui/joy/Typography';

export default function GenericSessions() {
    const net = NetworkHelper.getInstance();
    const [sessionsList, setSessionsList] = useState(null);

    const localizeDate = (dateString:string) => {
        const localDate = DateTime.fromISO(dateString).setZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
        return localDate.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
    }

    useEffect(() => {
        let cancelMe = false;

        const doAsyncWork = async () => {
            var result = null;
            result = await net.doGetRecentGenericSessions();

            if (!cancelMe) {
                if (!result) {
                    console.log("Sessions list never came back, that's unfortunate");
                }
                const listItems = result.map((session:any) =>
                    <tr key={session.id} style={{borderBottom: "1px solid blue"}}>
                        <td><Link to={`/SessionDetail/${session.id}`} component={RouterLink}>{session.id}</Link></td>
                        <td>{localizeDate(session.first_packet_date) }</td>
                        <td>{session.duration }</td>
                        <td>{session.mat_serial_number }</td>
                        <td>{session.im_serial_number }</td>
                    </tr>
                );
                setSessionsList(listItems);

            }
        }

        doAsyncWork().catch((error) => console.error(error));

        return () => {
            cancelMe = true;
        }
    }, [net]);

    if (sessionsList) {
        return (
        <>
            <div style={{padding: "10px"}}>
                <Typography level="h4" component="h1">Recent Sessions</Typography>
                The most recent generic sessions are below. Click on the session ID link to see more details.<br /><br />
                <table style={{ borderCollapse: "collapse"}}>
                    <tbody>
                        <tr style={{borderBottom: "1px solid red"}}>
                            <td><b>ID</b></td>
                            <td><b>First packet</b></td>
                            <td><b>Duration</b> &nbsp; &nbsp; </td>
                            <td><b>Mat</b></td>
                            <td><b>Implant</b></td>
                        </tr>
                        {sessionsList}
                    </tbody>
                </table>
            </div>
        </>
        );
    }
    else {
        return <p>Loading sessions list (maybe)...</p>
    }
}
