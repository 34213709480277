import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { NetworkHelper } from '../util/NetworkHelper';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';

// Since we already have an MSAL account logged in at this point, get the access token
// and stash it in the NetworkHelper singleton. Then redirect to the home page.

export default function MSLogin() {
    const { instance, inProgress, accounts } = useMsal();
    const [loggedIn, setLoggedIn] = useState(false);
    const navigate = useNavigate();
    const net = NetworkHelper.getInstance();

    useEffect(() => {
        let cancelMe = false;

        const accessTokenRequest = { scopes: ["user.read"], account: accounts[0] };

        const doAsyncWork = async () => {
            try {
                var accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);

                if (loggedIn || cancelMe) {
                    return;
                }

                if (accessTokenResponse.accessToken) {
                    console.log(`we have an access token now: ${accessTokenResponse.accessToken}`);
                    net.setAccessToken(accessTokenResponse.accessToken);

                    // We shouldn't get here any more after the navigateToLoginRequestUrl
                    // change to the msalconfig, but leave this here for now.
                    const redir = "/";

                    console.log(`login all done, redirecting to: ${redir}`);
                    //navigate(redir);

                    setLoggedIn(true);
                }
            }
            catch (error) {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(accessTokenRequest);
                }
                else {
                    console.error(error);
                }
            }
        };

        doAsyncWork().catch((error) => console.error(error));

        return () => {
            cancelMe = true;
        }
    }, [instance, accounts, inProgress, loggedIn, navigate, net]);

    // Maybe punch this up with a popup or something extra pretty.
        return (
            <Sheet
                sx={{
                    width: 400,
                    minHeight: 300,
                    mx: 'auto', // margin left & right
                    my: 4, // margin top & bottom
                    py: 3, // padding top & bottom
                    px: 2, // padding left & right
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    borderRadius: 'sm',
                    boxShadow: 'md',
                }}>
                <Typography level="h4" component="h1">MSLogin Redirect</Typography>
                This should not be visible for very long. If you see this for more than a second or two, something is wrong.<br /><br />
            </Sheet>
        );
}
