import Typography from '@mui/joy/Typography';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Input from '@mui/joy/Input';
import { useRef, useState } from 'react';

interface EditableLabelProps {
    initialText: string | null;
    labelKey: string;
    onTextChange: (labelKey: string, newText: string) => void;
}

export default function EditableLabel(props:EditableLabelProps) {
    const [isEditing, setIsEditing] = useState(false);
    const [currentText, setCurrentText] = useState(props.initialText);
    const editRef = useRef<HTMLInputElement>(null);

    const focusLost = () => {
        setIsEditing(false);
    }

    const handleEditClick = () => {
        console.log("Edit clicked");
        setCurrentText(props.initialText);
        setIsEditing(true);
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentText(event.target.value); // update currentText when input changes
    }

    const commitIfEnterPressed = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Escape') {
            setIsEditing(false);
        }

        if (event.key === 'Enter') {
            // TODO: commit it
            props.onTextChange(props.labelKey, currentText ?? '');
            setIsEditing(false);
        }
    };

    return (
        <>
            {isEditing && <Input size="sm" type="text" id={`edit_${props.labelKey}`} spellCheck={false} autoFocus onChange={handleInputChange}  onKeyDown={commitIfEnterPressed} onBlur={focusLost} slotProps={{ input: { ref: editRef } }} value={currentText ?? ''} />}
            {!isEditing && <Typography level="body-sm">{props.initialText}<ModeEditIcon onClick={handleEditClick} style={{ cursor: 'pointer', float: 'right' }} /></Typography>}
        </>
    );
}
