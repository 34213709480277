import { useEffect, useState } from 'react';
import Typography from '@mui/joy/Typography';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BlockIcon from '@mui/icons-material/Block';
import Input from '@mui/joy/Input';
import FlexBreak from '../components/FlexBreak';
import { useRef } from 'react';
import { Select, Option } from '@mui/joy';
import { NetworkHelper } from '../util/NetworkHelper';
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

interface TimeSeriesName {
    id: number;
    series_name: string;
    unit: string;
}

interface ReportForm {
    deviceType: string | null,
    serialNumber: string | null,
    seriesID: number | null,
    startDate: string,
    endDate: string
}

function numberOrNull(value: string | null | undefined): number | null {
    if (value === undefined || !value) {
        return null;
    }

    return parseInt(value);
}

export default function SingleSeries() {
    useEffect(() => {
        document.title = `Arcadia - Single Series Report`;
    }, []);

    const tsnQuery = useQuery({
        queryKey: ['getTimeSeriesNames'],
        queryFn: () => NetworkHelper.getInstance().doGetTimeSeriesNames()
    });

    const [searchParams] = useSearchParams();
    const {
        reset,
        register,
        control,
        handleSubmit
      } = useForm<ReportForm>(({
        defaultValues: {
            deviceType: searchParams.get("device") || "",
            serialNumber: searchParams.get("serial") || "",
            seriesID: numberOrNull(searchParams.get("series")),
            startDate: searchParams.get("start") || "",
            endDate: searchParams.get("end") || ""
        },
      }));

    const doFormReset = () => {
        window.history.replaceState({}, "", "/single_series")
        reset( { deviceType: null, serialNumber: null, seriesID: null, startDate: "", endDate: "" });
    };

    const navigate = useNavigate();
    const onSubmit: SubmitHandler<ReportForm> = (data) => {
        var queryString = `type=singleseries&device=${data.deviceType}&serial=${data.serialNumber}&series=${data.seriesID}`;

        if (data.startDate) {
            queryString += `&start=${data.startDate}`;
        }

        if (data.endDate) {
            queryString += `&end=${data.endDate}`;
        }

        window.history.replaceState({}, "", `/single_series/?${queryString}`);
        navigate(`/report?${queryString}`);
    }

    const [formErrorMessage, setFormErrorMessage] = useState<string>("");
    const serialNumberRef = useRef<HTMLInputElement>(null);
    const fireSearch = () => {
        var serialNumber = "";

        if (serialNumberRef.current!.value) {
            serialNumber = serialNumberRef.current!.value;
        }

        console.log(`Firing up search, serial number: ${serialNumber}`);

        if (serialNumber === "") {
            setFormErrorMessage("Serial number is required.");
            return;
        }
    };

    const fireSearchIfEnterPressed = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            fireSearch();
        }
    };

    if (tsnQuery.isError) {
        return <p>Error fetching time series names: {tsnQuery.error.message}</p>;
    }

    if (tsnQuery.isSuccess) {
        return (
        <>
            <Typography level="h2" component="h1">Single Series</Typography>
            <Typography level="body-md" sx={{
                color: 'red',
                display: formErrorMessage === "" ? 'none' : 'block'
            }}
            ><br />{ formErrorMessage }</Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
            <Box
                className="SearchAndFilters-tabletUp"
                sx={{
                    borderRadius: '1px',
                    borderWidth: '1px',
                    py: 2,
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 1.5,
                    '& > *': {
                    minWidth: { xs: '120px', md: '160px' },
                    },
                }}
                >

                <FormControl sx={{ flex: 0 }} size="sm">
                    <FormLabel>Device Type*</FormLabel>
                    <Controller
                        name="deviceType"
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                            <Select
                            {...field}
                            required={true}
                            placeholder="Choose one…"
                            onChange={(_event, newValue) => { field.onChange(newValue); }}
                            >
                                <Option value="Mat">Mat</Option>
                                <Option value="Implant">Implant</Option>

                            </Select>
                        )}
                    />
                </FormControl>

                <FormControl sx={{ flex: 0 }} size="sm">
                    <FormLabel>Serial Number*</FormLabel>
                    <Input placeholder="Serial"
                           size="sm"
                           type="text"
                           id="serialNumber"
                           onKeyDown={fireSearchIfEnterPressed}
                           {...register("serialNumber", { required: true })} />
                </FormControl>
                <FormControl sx={{ flex: 0 }} size="sm">
                    <FormLabel>Series*</FormLabel>

                    <Controller
                        name="seriesID"
                        control={control}

                        render={({ field }) => (
                            <Select
                            {...field}
                            required={true}
                            onChange={(_event, newValue) => { field.onChange(newValue); }}
                            placeholder="Choose one…">
                                {tsnQuery.data.map((row:TimeSeriesName, index:number) => (
                                    <Option key={row.id} value={row.id}>{row.series_name} ({row.unit})</Option>
                                ))}

                            </Select>
                            )}
                    />

                </FormControl>
                <FlexBreak />
                <FormControl sx={{ flex: 0 }} size="sm">
                    <FormLabel>Start date</FormLabel>
                    <Input size="sm" type="date" id="startDate" {...register("startDate")} />
                </FormControl>
                <FormControl sx={{ flex: 0 }} size="sm">
                    <FormLabel>End date</FormLabel>
                    <Input size="sm" type="date" id="endDate" {...register("endDate")} />
                </FormControl>
                <FlexBreak />
                <Button
                    type="submit"
                    color="primary"
                    startDecorator={<AssessmentIcon />}
                    size="sm"
                    sx={{ height: '32px', alignSelf: 'flex-end' }}
                    >
                    Build
                </Button>
                <Button
                    color="danger"
                    startDecorator={<BlockIcon />}
                    size="sm"
                    sx={{ height: '32px', alignSelf: 'flex-end' }}
                    onClick={doFormReset}
                    >
                    Reset
                </Button>
            </Box>
            </form>
        </>
        );
    }
    else {
        return <p>Fetching time series names...</p>
    }
}
