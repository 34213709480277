import { useEffect } from 'react';
import { NetworkHelper } from '../util/NetworkHelper';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import { useColorScheme } from '@mui/joy/styles';
import { getEvenRowTableBackground, getOddRowTableBackground, localizeDate, prettySize } from '../utils';
import Link from '@mui/joy/Link';
import { Link as RouterLink } from "react-router-dom";
import { useQuery } from '@tanstack/react-query';

// Simple test page to show the result of a call to the backend REST API.

export default function BugReports() {
    const bugReportsQuery = useQuery({
        queryKey: ['getBugReports'],
        queryFn: () => NetworkHelper.getInstance().doGetBugReports()
    });
        
    useEffect(() => {
      document.title = "Arcadia - Midnight Bug Reports";
  }, []);

  const { mode } = useColorScheme();
  const rowEvenBackground = getEvenRowTableBackground(mode!);
  const rowOddBackground = getOddRowTableBackground(mode!);


  if (bugReportsQuery.isError) {
    return <p>Error fetching list of bug reports: {bugReportsQuery.error.message}</p>;
    }

    if (!bugReportsQuery.isSuccess) {
        return <p>Loading...</p>;
    }
    else {
        return (
        <>
        <Typography level="h2" component="h1">Midnight Bug Reports</Typography>
        <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
        width: '100%',
        borderRadius: '1px',
        borderWidth: '1px',
        flexShrink: 0,
        overflow: 'auto',
        minHeight: 0,
        marginTop: '16px'
        }}
    >
        <Table
        aria-labelledby="tableTitle"
        stickyHeader
        hoverRow
        sx={{
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
            '--TableCell-paddingY': '4px',
            '--TableCell-paddingX': '8px',
        }}
        >
            <thead>
                <tr>
                    <th style={{ width: 10, padding: '12px 6px' }}>Filename</th>
                    <th style={{ width: 10, padding: '12px 6px' }}>Size</th>
                    <th style={{ width: 10, padding: '12px 6px' }}>Date</th>
                </tr>
            </thead>
            <tbody>
            {bugReportsQuery.data.map((row:any, index:number) => (
            <tr style ={ index % 2? { background : rowEvenBackground }:{ background : rowOddBackground }} key={row.filename}>
                <td>
                    <Link to={`${NetworkHelper.getInstance().getBackendURLStem()}bug_reports/${row.filename}`} component={RouterLink} download>{row.filename}</Link>
                </td>
                <td>
                    <Typography level="body-sm">{prettySize(row.size)}</Typography>
                </td>
                <td>
                    <Typography level="body-sm">{localizeDate(row.date)}</Typography>
                </td>
            </tr>
            ))}
            </tbody>
        </Table>
        </Sheet>
        </>
        );
    }
}
