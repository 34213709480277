import { getEvenRowTableBackground, getOddRowTableBackground } from '../utils';
import { useColorScheme } from '@mui/joy/styles';
import Typography from '@mui/joy/Typography';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import { localizeEpochShort } from '../utils';
import { useQuery } from '@tanstack/react-query';
import { NetworkHelper } from '../util/NetworkHelper';

interface DeviceLogProps {
    tablet_id: number;
    display: string;
}

export default function DeviceLogTable(props: DeviceLogProps) {
    const { mode } = useColorScheme();
    const rowEvenBackground = getEvenRowTableBackground(mode!);
    const rowOddBackground = getOddRowTableBackground(mode!);

    const logQuery = useQuery({
        queryKey: ['getDeviceLog', props.tablet_id],
        queryFn: () => NetworkHelper.getInstance().doGetDeviceLog(props.tablet_id)
    });

    const colorForLevel = (level:string) => {
        if (level === "I" || level === "D") {
            return "";
        }
        else if (level === "W") {
            return "orange";
        }

        return "red";
    }
    if (!logQuery.isSuccess) {
        return <Typography>Loading...</Typography>
    }

    return (
        <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
            width: '100%',
            borderRadius: '1px',
            borderWidth: '1px',
            flexShrink: 0,
            minHeight: 0,
            display: props.display
        }}>
            <Table
                aria-labelledby="tableTitle"
                sx={{
                    '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                    '--Table-headerUnderlineThickness': '1px',
                    '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                    '--TableCell-paddingY': '4px',
                    '--TableCell-paddingX': '8px',
                }}
            >
                <thead>
                    <tr>
                        <th style={{ width: "60px", padding: '12px 6px' }}>Level</th>
                        <th style={{ width: "160px", padding: '12px 6px' }}>Timestamp</th>
                        <th style={{ width: "auto", padding: '12px 6px' }}>Contents</th>
                    </tr>
                </thead>
                <tbody>
                    {logQuery.data.map((log_entry:any, index:number) => (
                    <tr style={ index % 2 ? { background : rowEvenBackground }:{ background : rowOddBackground }} key={index}>
                        <td>
                            <Typography level="body-sm" style={{color: colorForLevel(log_entry.level)}}>{ log_entry.level }</Typography>
                        </td>
                        <td>
                            <Typography level="body-sm" style={{color: colorForLevel(log_entry.level)}}>{ localizeEpochShort(log_entry.timestamp * 1000) }</Typography>
                        </td>
                        <td>
                            <Typography level="body-sm" style={{color: colorForLevel(log_entry.level)}}>{ log_entry.contents }</Typography>
                        </td>
                    </tr>
                    ))}
            </tbody>
            </Table>
        </Sheet>
    );
}