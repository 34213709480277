import Typography from '@mui/joy/Typography';

// Simple test page to show the result of a call to the backend REST API.

export default function Mats() {
    return (
    <>
        <Typography level="h2" component="h1">Mats</Typography>
        <span>
            TBD: build a list/search thing here for Mats.<br/><br />
        </span>
    </>
    );
}
