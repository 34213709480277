import { getEvenRowTableBackground, getOddRowTableBackground } from '../utils';
import { useColorScheme } from '@mui/joy/styles';
import Typography from '@mui/joy/Typography';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';

type MetadataEntry = {
    md_key: string;
    md_value: string;
    is_generated: boolean;
};

interface MetadataTableProps {
    metadata: MetadataEntry[];
}

export default function MetadataTable(props:MetadataTableProps) {
    const { mode } = useColorScheme();
    const rowEvenBackground = getEvenRowTableBackground(mode!);
    const rowOddBackground = getOddRowTableBackground(mode!);

    return (
        <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
            width: '100%',
            borderRadius: '1px',
            borderWidth: '1px',
            flexShrink: 0,
            minHeight: 0,
        }}
    >
        <Table
            aria-labelledby="tableTitle"
            sx={{
                '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                '--Table-headerUnderlineThickness': '1px',
                '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                '--TableCell-paddingY': '4px',
                '--TableCell-paddingX': '8px',
            }}
        >
            <thead>
                <tr>
                    <th style={{ width: 15, padding: '12px 6px' }}>Generated</th>
                    <th style={{ width: 40, padding: '12px 6px' }}>Key</th>
                    <th style={{ width: 40, padding: '12px 6px' }}>Value</th>
                </tr>
            </thead>
            <tbody>
            {props.metadata.map((row:any, index:number) => (
                    <tr style ={ index % 2? { background : rowEvenBackground }:{ background : rowOddBackground }} key={index}>
                        <td align="center">
                            <Typography level="body-sm">{row.is_generated ? "*" : "" }</Typography>
                        </td>
                        <td>
                            <Typography level="body-sm">{row.md_key}</Typography>
                        </td>
                        <td>
                            <Typography level="body-sm">{row.md_value}</Typography>
                        </td>
                    </tr>
                    ))}
            </tbody>
        </Table>
    </Sheet>
);
}