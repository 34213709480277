import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from '@azure/msal-browser';
import { msalConfig, msalConfigDev } from './msalConfig';
import { BrowserRouter } from 'react-router-dom';
import '@fontsource/inter';

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */

export const isDevMode = !process.env.NODE_ENV || process.env.NODE_ENV === "development"
export const msalInstance:PublicClientApplication = new PublicClientApplication(isDevMode ? msalConfigDev : msalConfig);

if (isDevMode) {
    console.warn("No production environment, assuming dev mode!");
}

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event:EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const account = (event.payload as AuthenticationResult).account;
        msalInstance.setActiveAccount(account);
    }
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>
);

