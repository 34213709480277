import { DateTime} from 'luxon';

export interface MetadataRow {
  key: string;
  value: string;
}

export function openSidebar() {
    if (typeof window !== 'undefined') {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.setProperty('--SideNavigation-slideIn', '1');
    }
  }

  export function closeSidebar() {
    if (typeof window !== 'undefined') {
      document.documentElement.style.removeProperty('--SideNavigation-slideIn');
      document.body.style.removeProperty('overflow');
    }
  }

export function toggleSidebar() {
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    const slideIn = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue('--SideNavigation-slideIn');
    if (slideIn) {
      closeSidebar();
    } else {
      openSidebar();
    }
  }
}

export function prettySize(size_in_bytes:number):string {
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let unitIndex = 0;
  let size = size_in_bytes;
  while (size >= 1024) {
    size /= 1024;
    unitIndex++;
  }
  return size.toFixed(2) + ' ' + units[unitIndex];
}

export function localizeDate(dateString:string):string {
  const localDate = DateTime.fromISO(dateString).setZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
  return localDate.toLocaleString({ month: 'short', day: '2-digit', year: 'numeric', hour: 'numeric', minute: '2-digit' });
}

export function localizeDateShort(dateString:string):string {
  const localDate = DateTime.fromISO(dateString).setZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
  return localDate.toLocaleString({ month: '2-digit', day: '2-digit', hour: 'numeric', minute: '2-digit' }).replaceAll(",", "");
}

export function localizeEpoch(epoch:number):string {
  const localDate = DateTime.fromMillis(epoch).setZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
  return localDate.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
}

export function localizeEpochShort(epoch:number):string {
  const localDate = DateTime.fromMillis(epoch).setZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
  return localDate.toLocaleString({ month: '2-digit', day: '2-digit', hour: 'numeric', minute: '2-digit' }).replaceAll(",", "");
}

export function localizeEpochJustTime(epoch:number):string {
  const localDate = DateTime.fromMillis(epoch).setZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
  return localDate.toLocaleString(DateTime.TIME_WITH_SECONDS);
}

export function pickColorByMode(mode:string, lightColor:string, darkColor:string) {
  return mode === 'dark' ? darkColor : lightColor;
}

export function getEvenRowTableBackground(mode:string):string {
  return mode === 'dark' ? '#101010' : '#e0e0e0';
}

export function getOddRowTableBackground(mode:string):string {
  return mode === 'dark' ? '#202020' : '#f0f0f0';
}

// TODO: implement getErrorTableBackground to highlight sessions with errors (bist/post failure or other heuristic)

export function findMetadataValue(metadata: MetadataRow[], key: string): string {
  if (metadata) {
    for (const row of metadata) {
        if (row.key === key) {
            return row.value;
        }
    }
  }

  return "";
}

export function metadataHasSelfTestFailure(metadata: MetadataRow[]): boolean {
  if (metadata) {
    for (const row of metadata) {
        if (row.key === "SelfTestFailure" && row.value === "True") {
            return true;
        }
    }
  }

  return false;
}

export function emptyIfNull(str: string | null): string {
    if (str == null) {
        return "";
    }
    else {
        return str;
    }
}