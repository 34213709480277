import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { NetworkHelper } from '../util/NetworkHelper';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import Link from '@mui/joy/Link';
import { Link as RouterLink } from "react-router-dom";
import { useColorScheme } from '@mui/joy/styles';
import { getEvenRowTableBackground, getOddRowTableBackground, localizeDate } from '../utils';
import EditableLabel from '../components/EditableLabel';

export default function Neurostimulators() {
    const queryClient = useQueryClient();

    const updateNotesMutation = useMutation({
        mutationFn: (options: any) => NetworkHelper.getInstance().doUpdateImplantNotes(options.serial_number, options.notes),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['getImplants'] });
        }
    });

    useEffect(() => {
        document.title = `Arcadia - Neurostimulators`;
    }, []);

    const implantsQuery = useQuery({
        queryKey: ['getImplants'],
        queryFn: () => NetworkHelper.getInstance().doGetImplants()
    });

    const onImplantNotesChanged = (labelKey: string, newNotes: string) => {
        console.log(`Implant notes changed: ${labelKey} -> ${newNotes}`);
        updateNotesMutation.mutate({ serial_number: labelKey, notes: newNotes });
    }

    const { mode } = useColorScheme();
    const rowEvenBackground = getEvenRowTableBackground(mode!);
    const rowOddBackground = getOddRowTableBackground(mode!);

    if (implantsQuery.isError) {
        return <p>Error fetching list of Neurostimulators: {implantsQuery.error.message}</p>;
    }

    if (!implantsQuery.isSuccess) {
        return <p>Loading...</p>;
    }
    else {
        return (
        <>
        <Typography level="h2" component="h1">Neurostimulators</Typography>
        <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          width: '100%',
          borderRadius: '1px',
          borderWidth: '1px',
          flexShrink: 0,
          overflow: 'auto',
          minHeight: 0,
          marginTop: '16px'
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
            '--TableCell-paddingY': '4px',
            '--TableCell-paddingX': '8px',
          }}
        >
            <thead>
                <tr>
                    <th style={{ width: 10, padding: '12px 6px' }}>Serial</th>
                    <th style={{ width: 10, padding: '12px 6px' }}>First Seen</th>
                    <th style={{ width: 10, padding: '12px 6px' }}>Last Seen</th>
                    <th style={{ width: 10, padding: '12px 6px' }}>Last FW Version</th>
                    <th style={{ width: 30, padding: '12px 6px' }}>Notes</th>
                </tr>
            </thead>
            <tbody>
            {implantsQuery.data.map((row:any, index:number) => (
              <tr style ={ index % 2? { background : rowEvenBackground }:{ background : rowOddBackground }} key={row.serial_number}>
                <td>
                <Link to={`/sessions?metadata=${row.serial_number}`} component={RouterLink}>{row.serial_number}</Link>
                </td>
                <td>
                    <Typography level="body-sm">{localizeDate(row.first_seen)}</Typography>
                </td>
                <td>
                    <Typography level="body-sm">{localizeDate(row.last_seen)}</Typography>
                </td>
                <td>
                    <Typography level="body-sm">{row.last_fw_version}</Typography>
                </td>
                <td>
                    <EditableLabel initialText={row.notes} labelKey={row.serial_number} onTextChange={onImplantNotesChanged} />
                </td>
              </tr>
            ))}
            </tbody>
        </Table>
        </Sheet>
        </>
        );
    }
}
