import { useState, useEffect } from 'react';
import { ReactNode } from 'react';
import { NetworkHelper } from './util/NetworkHelper';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import Axios from 'axios'

import { signal } from '@preact/signals-react';

export const userName = signal("username");
export const userEmail = signal("useremail");
export const userIcon = signal("usericon");

interface Props {
    children: ReactNode;
}

export default function OurAuthTokenWrapper({ children }: Props) {
    const { instance, inProgress, accounts } = useMsal();
    const net = NetworkHelper.getInstance();
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        let cancelMe = false;

        const accessTokenRequest = { scopes: ["user.read"], account: accounts[0] };

        const doAsyncWork = async () => {
            try {
                if (net.hasAccessToken() || cancelMe) {
                    console.log(`we have an access token already, bailing out quickly`);
                    return;
                }

                var accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);

                if (accessTokenResponse.accessToken) {
                    net.setAccessToken(accessTokenResponse.accessToken);

                    userName.value = instance.getActiveAccount()?.name || '?unknown?';
                    userEmail.value = instance.getActiveAccount()?.username || '?unknown?';

                    try {
                        const response = await Axios.get('https://graph.microsoft.com/v1.0/me/photo/$value', {
                                                 headers: { 'Authorization': 'Bearer ' + accessTokenResponse.accessToken },
                                                    responseType: 'blob'});
                        const url = window.URL || window.webkitURL;
                        const blobUrl = url.createObjectURL(response.data);
                        userIcon.value = blobUrl;
                    }
                    catch (error:any) {
                        if (error.response.status === 404) {
                            console.log('No user icon found');
                        }
                        else {
                            console.error(error);
                        }
                    }

                    setLoggedIn(true);
                }
            }
            catch (error) {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(accessTokenRequest);
                }
                else {
                    console.error(error);
                }
            }
        };

        if (!net.hasAccessToken()) {
            doAsyncWork().catch((error) => console.error(error));
        }

        return () => {
            cancelMe = true;
        }
    }, [instance, inProgress, accounts, net, loggedIn]);

    if (loggedIn) {
        return <>{children}</>;
    }
    else {
        return <><span>Getting auth token from Microsoft...</span></>;
    }
}
